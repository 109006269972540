<template>
  <section>
    
          <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <div slot="emptystate" class="text-center">
        {{ $t('Global.Emptystate') }}
      </div>
      <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'" class="action">
          <PrintAccounting
            :row="props.row"
          ></PrintAccounting>
        </span>
        <span v-else-if="props.column.field === 'month'">
          {{ $t(`Management.Accounting.Months.${props.formattedRow[props.column.field]}`) }}
        </span>
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>

      </template>
  
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap px-2 pb-2">
          <div class="d-flex align-items-center mb-0 mt-1 flex-wrap">
            <span class="text-nowrap">
              {{ $t('Global.ShowingOneOf') }}
            </span>
            <v-select
              v-model="pageLength"
              class="mx-1"
              style="min-width: 75px"
              :clearable="false"
              :options="['10', '15', '30']"
              @input="value => props.perPageChanged({ currentPerPage: value })"
            />
            <span class="text-nowrap">{{ $t('Global.EntriesShown') }}</span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="value => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </section>
  
    
  </template>
  
  <script>
  import { BPagination } from 'bootstrap-vue'
  import { VueGoodTable } from 'vue-good-table'
  import vSelect from 'vue-select'
  import PrintAccounting from "../components/print.vue";
  export default {
    components: {
      VueGoodTable,
      vSelect,
      BPagination,
      PrintAccounting,
    },
    props: {
      countries: {
        type: Array,
        default: () => [],
      },
      rows: {
        type: Array,
        default: () => [],
      },
      searchTerm: {
        type: String,
        default: () => '',
      },
    },
  
    data() {
      return {

        pageLength: 10,
        columns: [
          {
            label: this.$t('Management.Accounting.Shareholder'),
            field: 'shareholder',
          },
          {
            label: this.$t('Management.Accounting.Month'),
            field: 'month',
          },
          {
            label: this.$t('Management.Accounting.Year'),
            field: 'year',
          },
          {
            label: this.$t('Management.Accounting.ProcessType'),
            field: 'processType',
          },
          {
            label: this.$t('Global.Action'),
            field: 'action',
            sortable: false,
          },
        ],
      }
    }

  }
  </script>
  